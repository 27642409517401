import kingfeadfafImage from '../../../images/kingfeadfaf.png';
import diceImage from '../../../images/dice2.png';
import priceImage from '../../../images/price.png';

export const items = [
	{
		title: 'About',
		img: { src: kingfeadfafImage, alt: 'kingfeadfaf' },
		text: `The Theta-wave service is not a game platform. The company is limited to the mathematical processing of queries and is not responsible for the application of calculation results by end-users.
		However, the service is not intended to explain in detail all the mathematics used to solve problems, especially to an insufficiently educated user. Its learning function is more utilitarian. But that’s why it can be useful to anyone interested in GTO (Game Theory Optimal) and training brain-hungry games’ skills in general: from risk-adverse beginners to more advanced intellectuals. These games have been extensively studied from a mathematical standpoint, as it is interesting from a game theory standpoint and highlights considerations that must be made when making decisions under uncertainty and deals with expected value of strategies over time. It is the games with strategies that are not immediately intuitive and the value of those strategies are only seen over a large number of variants.`,
	},
	{
		title: 'How it works',
		img: { src: diceImage, alt: 'dice' },
		text: `To reduce complexity, the AI-RPM (Request Processing Machine) on the input gets a description of the current situation in the user-selected game (chess, Go, poker, for example). The situation is described by the chosen template. As a response, the system gives a variant of the best move. At the same time the service is able to provide not only a best-moves calculator, but also game analytics as well.`,
	},
	{
		title: 'Payment',
		img: { src: priceImage, alt: 'price' },
		text: `After making a prepayment you get access to the service. We have provided for you the most convenient option - package service.The more requests  for processing mathematical solutions you pay, the cheaper will be the price for each connection.The more services you pay for on our site, the more bonuses we will give you.   You can always find the actual prices and packages in the Price section.

		At the time of payment, you need to go through verification (this is a requirement of payment systems).
		`,
	},
];
