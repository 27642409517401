import clsx from 'clsx';
// import { Link } from 'gatsby';
import React, {useCallback, useContext} from 'react';
import './style.scss';
import {Modal} from "antd";
import {userStoreContext} from "../../stores/UserStore";
import {pricesStoreContext} from "../../stores/PricesStore";

const Card = ({ cardData, buyPathRedirect }) => {
	const { id, title, costTip, countPaidTips, countFreeTips, totalPrice } = cardData;
	const { user } = useContext(userStoreContext);
	const { buyNow } = useContext(pricesStoreContext);

	const handleBuyNow = useCallback((e, tariffName, tariffId) => {
		if (!!user) {
			buyNow(tariffId).then(r => console.log(r));
			Modal.info({
				centered: true,
				title: 'Buy now',
				content: (
					<div>
						<span>Thank you for choosing the Theta Wave {tariffName}.</span><br/>
						<span>
						Our team will be in touch with you shortly via email to provide you with all the
						necessary information and details about your subscription.
					</span>
					</div>
				),
			});
		} else {
			window.location.href = "/#register";
		}
	}, []);

	return (
		<div
			className={clsx(
				'w-80 max-w-sm card text-center px-5 py-10 rounded-3xl transform transition duration-500 hover:scale-105',
			)}
		>
			<h2 className="text-3xl mb-14 font-bold">{title}</h2>

			<div className="text-3xl">
				<h4 className="mb-8">{`$ ${totalPrice}`}</h4>
				<p className="mb-8">{`1 request cost`}</p>
				<p className="mb-8">{`$ ${countPaidTips>0 ? (Math.round(100*totalPrice/countPaidTips)/100) : '-'}`}</p>
				<p className="mb-8">{`+ ${countFreeTips} requests`}</p>
				<p className="mb-8">{`Free`}</p>
			</div>
			{/*<Link to={buyPathRedirect} className="bg-main-blue px-20 py-4 rounded-xl">Buy Now</Link>*/}
			<a className="bg-main-blue px-20 py-4 rounded-xl"
			   onClick={(e) => handleBuyNow(e, title, id)}>Buy Now</a>
			<div className="text-3xl mt-8">{`${countPaidTips + countFreeTips} requests`}</div>
		</div>
	);
};

export default Card;
