import React from 'react';
import About from './About/About';
import EmailForm from './EmailForm/EmailForm';
import Price from './Price/Price';
import StartSteps from './StartSteps/StartSteps';
import UseService from './UseService/UseService';
import WelcomeScreen from './WelcomeScreen/WelcomeScreen';
import diceImage from '../../images/dice2.png';

interface Props {}

const Landing = (props: Props) => {
	return (
		<>
			<div className="font-regular text-base mx-auto overflow-hidden max-w-screen-2xl">
				<div className="bg-landing-top bg-no-repeat bg-cover" style={{ backgroundPosition: '100% 10%' }}>
					<div className="container relative">
						<WelcomeScreen pathForRedirect={'/#register'} />
						<UseService />
						<StartSteps />
						<img src={diceImage} alt="dice" className="w-1/3 absolute bottom-[-8rem] right-[-15%]" />
					</div>
				</div>

				<div className="bg-landing-bottom bg-no-repeat bg-cover">
					<div className="container">
						<Price />
						<About />
					</div>
				</div>

				<EmailForm />
			</div>
		</>
	);
};

export default Landing;
