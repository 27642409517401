import clsx from 'clsx';
import React from 'react';
import './style.scss';

type Props = {
	error?: string | boolean;
	frameless?: boolean;
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const AppInput = ({ error, frameless, ...props }: Props) => {
	return (
		<div className={clsx('app-input text-left w-full', { empty: !props.value }, props.className)}>
			<div className="relative">
				<input
					{...props}
					className={clsx(
						'rounded-sm transition-all w-full text-black',
						{
							'border-red-400': !!error,
						},
						{
							'border-0': frameless,
						},
					)}
				></input>
				{/* <label className="absolute left-2 transition-all text-black bg-white px-2">{props.placeholder}</label> */}
			</div>
			{error && <p className="font-bold text-red-400">{error}</p>}
		</div>
	);
};

export default AppInput;
