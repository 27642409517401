import clsx from 'clsx';
import React from 'react';
import { items } from './data';

type Props = {};

const About = (props: Props) => {
	return (
		<section className="mb-16 pt-16" id="about">
			{items.map((item, index) => (
				<article key={index} className="flex justify-between mb-20">
					<div className={clsx('md:max-w-[60%]', { 'order-1': index % 2 !== 0 })}>
						<h2 className="mb-5 text-6xl font-title font-bold">{item.title}</h2>
						<div className="font-medium">{item.text}</div>
					</div>
					<div className="hidden md:block w-[25%] text-center">
						<img className="max-h-[400px]" src={item.img.src} alt={item.img.alt} />
					</div>
				</article>
			))}
		</section>
	);
};

export default About;
