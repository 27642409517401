import { Link } from 'gatsby';
import { observer } from 'mobx-react-lite';
import React, { useContext, useMemo, useState } from 'react';
import { AppButton } from '../../../components/core/AppButton';
import AppInput from '../../../components/core/AppInput/AppInput';
import { authStoreContext } from '../../../stores/AuthStore';
import { checkEmail } from '../../../utils/validation';

interface Props {}

const EmailForm = (props: Props) => {
	const { signUp } = useContext(authStoreContext);

	const [agree, setAgree] = useState(false);
	const [email, setEmail] = useState('');
	const [error, setError] = useState('');
	const [isSended, setIsSended] = useState(false);

	const handleConfirmEmail = () => {
		setError('');

		signUp(email)
			.then(() => {
				setIsSended(true);
			})
			.catch((error) => {
				setError(error.description);
			});
	};

	const isDisable = useMemo(() => {
		return !agree || !checkEmail(email);
	}, [agree, email]);

	return (
		<section id="register" className="flex flex-col items-center bg-second-blue text-white p-8 text-center">
			<div className="max-w-md flex flex-col items-center p-8">
				{isSended ? (
					<p>{`Check your inbox in ${email} to create account`}</p>
				) : (
					<>
						<h3 className="mb-8 text-2xl">Rest your email to start using our service</h3>
						<AppInput
							className="mb-8"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							type="email"
							placeholder="Enter your email"
							error={error}
							frameless
						></AppInput>

						<label className="flex items-center mb-8" htmlFor="agreement">
							<input
								className="mr-2"
								onChange={() => setAgree(!agree)}
								checked={agree}
								type="checkbox"
								id="agreement"
							/>
							<span className="text-black">
								Accept{' '}
								<Link to="/terms" className="underline">
									Terms of Service
								</Link>{' '}
								and{' '}
								<Link to="/privacy-policy" className="underline">
									Privacy Policy
								</Link>
							</span>
						</label>

						<AppButton
							className="!bg-white !text-blue-500"
							onClick={handleConfirmEmail}
							disabled={isDisable}
						>
							Send mail
						</AppButton>
					</>
				)}
			</div>
		</section>
	);
};

export default observer(EmailForm);
