import React from 'react';

interface Props {}

const UseService = (props: Props) => {
	return (
		<section id="service" className="pt-32 centered text-white">
			<div className="pt-12 pb-24 border-2 border-solid border-white rounded-2xl">
				<h2 className="mb-16 text-6xl font-title font-bold text-center">For whom we work </h2>
				<div className="flex text-3xl flex-col gap-10 items-center md:flex-row md:justify-around">
					<div className="w-1/3 min-w-[300px] text-center">
						Schools specializing in game teaching (sports simulators)
					</div>
					<div className="w-1/3 min-w-[300px] text-center">Self-study players (best moves calculator)</div>
				</div>
			</div>
			<div className="w-4/5 md:w-1/3 md:min-w-[500px] mt-20 text-center text-2xl">
				We have some great plans for different games, and we don’t limit ourselves to their level of complexity.
			</div>
		</section>
	);
};

export default UseService;
