import { makeAutoObservable } from 'mobx';
import { createContext } from 'react';
import { PricesApi, IPrice, NEW_PRICE } from '../services/api/Prices';

class PricesStore {
	prices: IPrice[] = [];

	constructor() {
		makeAutoObservable(this);
	}

	fetchPrices = async () => {
		try {
			const response = await PricesApi.getAll();
			this.prices = response.items.sort(
				(priceA, priceB) => priceA.countPaidTips * priceA.costTip - priceB.countPaidTips * priceB.costTip,
			);
		} catch (error) {}
	};

	setPrices = (newPrices: IPrice[]) => {
		this.prices = newPrices;
	};

	savePrice = async (id, row: IPrice) => {
		try {
			const savedPrice = id === NEW_PRICE.id ? await PricesApi.create(row) : await PricesApi.edit({ ...row, id });

			let newData = [...this.prices];
			const index = newData.findIndex((item) => savedPrice.id === item.id);

			if (index > -1) {
				const item = newData[index];
				newData.splice(index, 1, {
					...item,
					...row,
				});
			} else {
				newData = newData.filter((price) => price.id !== NEW_PRICE.id);
				newData.push(row);
			}

			this.setPrices(newData);
		} catch (error) {
			console.log('error: ', error);
		}
	};

	deletePrices = async (pricesIds: number[]) => {
		try {
			await PricesApi.delete({ data: { pricesIds } });

			const newDataPrices = this.prices.filter((price) => !pricesIds.includes(price.id));
			this.setPrices(newDataPrices);
		} catch (error) {
			console.log('error: ', error);
		}
	};

	buyNow = async (id: number) => {
		try {
			await PricesApi.buyNow({'id' : id});
		} catch (error) {
			console.log('error: ', error);
		}
	};
}

export const pricesStore = new PricesStore();
export const pricesStoreContext = createContext(pricesStore);
