import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import Card from '../../../components/CardPrice/Card';
import Loader from '../../../components/Loader/Loader';
import { pricesStoreContext } from '../../../stores/PricesStore';
import clsx from 'clsx';

const Price = () => {
	const { fetchPrices, prices } = useContext(pricesStoreContext);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setIsLoading(true);

		fetchPrices().finally(() => {
			setIsLoading(false);
		});
	}, []);

	return (
		<section id="price" className="pt-16">
			<h2 className="text-center mb-16 text-6xl font-title font-bold">Price list</h2>
			{isLoading ? (
				<Loader />
			) : (
				<div className={clsx('flex flex-wrap justify-center gap-4 mb-16')}>
					{prices.map((cardData, index) => (
						<Card key={index} cardData={cardData} buyPathRedirect="/#register" />
					))}
				</div>
			)}
			<div className="max-w-sm mx-auto text-center text-2xl">We present 1000 requests to all registred users</div>
		</section>
	);
};

export default observer(Price);
