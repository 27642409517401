import React, { Fragment } from 'react';
import clsx from 'clsx';
import arrowImage from '../../../images/arrow.png';

interface Props {}

const STEPS = [
	'Read the Rules of Use of Services and Accept a Public Offer',
	'Select the game',
	'Register and get access to your personal account',
	'Pay the package of services advantageous for you',
	'Connect via API or enter data manually',
];

const StartSteps = (props: Props) => {
	return (
		<section id="steps" className="pt-32 centered text-white">
			<h2 className="mb-5 text-6xl font-title font-bold text-center">What is required of you</h2>
			<div className="w-4/5 md:w-1/3 md:min-w-[500px] text-center text-2xl">
				Already Today we are ready to offer you our products in Poker...
			</div>
			<div className="hidden md:grid grid-cols-2 w-[80%] pt-32">
				{STEPS.map((step, index) => (
					<Fragment key={index}>
						<div className="z-10 h-20 mb-10 odd:pr-24 even:pl-24 odd:text-right even:text-left relative font-medium">
							<div className="text-2xl ">{step}</div>
							<div
								className={clsx(
									'z-1 opacity-30 absolute text-6xl p-8 w-[2em] h-[2em] top-[-5rem] text-center bg-sky-700 rounded-full',
									{ 'right-24': index % 2 === 0 },
									{ 'left-24': index % 2 !== 0 },
								)}
							>
								{index + 1}
							</div>
							{index !== STEPS.length - 1 && (
								<img
									src={arrowImage}
									alt="arrow"
									className={clsx(
										'w-[130px] h-[130px] absolute bottom-[-4rem]',
										{
											'transform scale-x-[-1]': index % 2 !== 0,
										},
										{
											'right-[-4rem]': index % 2 === 0,
										},
										{
											'left-[-4rem]': index % 2 !== 0,
										},
									)}
								/>
							)}
						</div>
						<div className="h-3" />
						<div className="h-3" />
					</Fragment>
				))}
			</div>
			<ol className="md:hidden font-medium mt-4">
				{STEPS.map((step, index) => (
					<li key={index} className="mb-3">
						{step}
					</li>
				))}
			</ol>
		</section>
	);
};

export default StartSteps;
