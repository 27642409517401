import { navigate } from 'gatsby';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Landing from '../modules/Landing/Landing';
import { jwtService } from '../services/JWTService';
import { authStoreContext } from '../stores/AuthStore';
import { userStoreContext } from '../stores/UserStore';
import useStartPagePath from '../hooks/useStartPagePath';

const IndexPage = () => {
	const { auth } = React.useContext(authStoreContext);
	const { user } = React.useContext(userStoreContext);
	const startPagePath = useStartPagePath();

	useEffect(() => {
		if (!jwtService.getToken()) return;
		if (user) navigate(startPagePath);
		auth().then(() => {
			navigate(startPagePath);
		});
	}, []);

	return (
		<>
			<Helmet>
				<title>ThetaWave Poker App</title>
			</Helmet>
			<Landing />
		</>
	);
};

export default observer(IndexPage);
