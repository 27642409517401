import { Link } from 'gatsby';
import React from 'react';
import { AppButton } from '../../../components/core/AppButton';
import kingfeadfafImage from '../../../images/kingfeadfaf.png';

interface Props {
	pathForRedirect: string;
}

const WelcomeScreen = ({ pathForRedirect }: Props) => {
	return (
		<section className="flex pt-40 pb-4 md:pb-44">
			<div className="hidden md:block basis-[30%] px-12">
				<img src={kingfeadfafImage} alt="kingfeadfaf" className="w-full" />
			</div>
			<div className="landing__welcome-title flex-1">
				<h1 className="text-6xl font-title">ThetaWave</h1>
				<div className="text-2xl mt-9 mb-9">
					<p className="!mb-9">
						We believe that mathematics can and must defeat human intelligence in the most complex and
						difficult-to-calculate games.
					</p>
					<p className="!mb-9">
						We explore games and offer audiences from around the world technologies that can play better and
						learn faster than humans. We only offer technology and are not responsible for the results of
						its use
					</p>
				</div>

				<Link to={pathForRedirect}>
					<AppButton className="text-3xl py-2 px-24">Try now</AppButton>
				</Link>
			</div>
		</section>
	);
};

export default WelcomeScreen;
