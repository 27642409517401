import React from 'react';
import './style.scss';

interface Props {
	text?: string;
}

const Loader = ({ text }: Props) => {
	return (
		<div className="centered">
			<div className="text-base">{text && text}</div>
			<LoaderIcon />
		</div>
	);
};

export const LoaderIcon = () => {
	return (
		<div className="lds-ripple">
			<div></div>
			<div></div>
		</div>
	);
};

export default Loader;
